import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed, watch } from 'vue';
import { metersToFeet, limitStringStart, limitStringEnd, renderHeight, renderSpeed, pointShortName, renderDistance, renderBearing } from '../Helpers.js';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { sourcesStore } from "@/stores/SourcesStore.js";

import { distance } from "@turf/distance";
import { bearing } from "@turf/bearing";
import { point as turfPoint } from "@turf/helpers";
import { measureToolStore } from "../stores/MeasureToolStore.js";

export const pointsStore = defineStore('points', () => {

	const messages = messagesStore();
	const points = []; // main list of points no reactive
	const pointsChanged = ref(0);
	const pointsCount = ref(0);
	const legendPoints = ref([]); // only store ref version of points IF <200 for the legend
	const selected = ref(null); // current point that is selected. Actual full object
	const withLabelsCount = ref();
	const { goToPoint } = useMapTools();

	const settings = settingsStore();
	const app = appStateStore();
	const sources  = sourcesStore();
	const measureToolData = measureToolStore();

	// const pointsCount = computed(() => {
	// 	return points.length;
	// });

	// return all the keys currently loaded
	const getAllKeys = function() {
		return points.map(point => point.key);
	}


	const getKey = function(key) {
		//console.log('looking for ' + key);
		return points.find((point) => {
			return point.key == key
		})
	}

	const getLegendByKey = function(key) {
		//console.log('looking for ' + key);
		return legendPoints.value.find((point) => {
			return point.key == key
		})
	}

	watch(() => app.selected, (currentValue, oldValue) => {
		//console.log('new app.selected changed  from' + oldValue + ' to ' + currentValue);
		setSelected();
	});

	const getTaskStats = function(key) {
		let st = app.compStats.find((stat) => {
			return stat.key == key
		});

		let stats = {};

		stats.task_leg = null;
		stats.task_order = null;

		if (!st) return;

		if (st.turnpoints[0].type=='start') {
			stats.start_ts = st.turnpoints[0].ts;
			stats.task_leg = 1;
		}

		// calculate speed
		stats.task_speed = null;
		if (st.turnpoints?.length>0)
		{
			let task_distance = st.turnpoints[st.turnpoints.length-1].distance_cumulative;
			let task_time = st.turnpoints[st.turnpoints.length-1].seconds_from_start;
			if (task_time && task_distance) {
				stats.task_speed = task_distance / task_time;
			}
		}

		// add current leg
		for (var j=0; j<st.turnpoints.length; j++) {
			stats.task_order = st.turnpoints[j].order; // for ordering
			if (st.turnpoints[j].type=='finish') stats.task_leg = 'F';
			else {
				if (st.turnpoints[j].type=='point') {
					//console.log(st.turnpoints[j]);
					stats.task_leg = st.turnpoints[j].order;
				}
			}
		}

		stats.legs = st;

		return stats;
	}


	const addTaskStats = function() {
		for (let i=0; i < legendPoints.value.length; i++) {
			let stats = getTaskStats(legendPoints.value[i].key);
			if (stats) {
				legendPoints.value[i].start_ts = stats.start_ts;
				legendPoints.value[i].task_speed = stats.task_speed;
				legendPoints.value[i].task_leg = stats.task_leg;
			}
		}
	}

	function checkForSelected() {

		// check if we were searching for a target, if so alert if not found
		//console.log('looking for ' + app.searchingForTarget);
		if (app.searchingForTarget!=null) {

			let name = app.searchingForTarget;
			if (selected.value) name = pointShortName(selected.value, 20);

			let foundKey = getKey(app.searchingForTarget);
			if (!foundKey) {
				messages.error('No recent location for ' + name + ' found');
			} else {

				messages.success('Found ' + name);

				goToPoint(foundKey);
				app.showLegend="selected";
			}
			app.searchingForTarget = null;
		}

	}


	const setSelected = function() {
		// console.log('SetSelected app.selected = ');
		// console.log(points);
		// console.log(app.selected);

		let selectedItem = points.find((point) => {
			return point.key == app.selected
		});

		// only update the selected value if we find something
		if (selectedItem!==undefined) {
			// console.log('Found = ');
			// console.log(selectedItem.key);
			selected.value=selectedItem;

		}
		// console.log('Set selected called, now:');
		// console.log(selected.value);
	};




	const isPointUnidentifiedOrNot = function(point) {
		if (point.label==null 
			&& point.rego==null 
			&& point.comp==null) return true;
		return false;
	}

	const clearSelected = function() {
		selected.value = null;
	};


	const getSmallLabel = function(point) {
		var str = [];

		if (settings.showHeightLabel && point.alt!==null) {
			str.push(renderHeight(point.alt, settings.altitudeUnits));
		}

		if (settings.showAGLLabel && point.agl!==null) {
			str.push(renderHeight(point.agl, settings.altitudeUnits));
		}

		if (settings.showVSpeedLabel && point.vspeed!==null) {
			str.push(renderSpeed(point.vspeed, settings.verticalUnits, 1));
		}

		if (settings.showSpeedLabel && point.speed!==null) {
			str.push(renderSpeed(point.speed, settings.speedUnits));
		}

		if (settings.showNameLabel && point.name) {
			str.push(point.name);
		}
		if (settings.showNameLabel && point.comp_name) {
			str.push(point.comp_name);
		}

		if (settings.showClassLabel && point.class) {
			str.push(point.class);
		}
		if (settings.showRegoLabel && point.rego) {
			str.push(point.rego);
		}

		if (settings.showSourceLabel && point.source_id!==null) {
			str.push(sources.getSource(point.source_id)?.name);
		}

		if (settings.showTargetDistanceLabel && point.targetDistance) {
			str.push(renderDistance(point.targetDistance, settings.distanceUnits));
		}

		if (settings.showTargetBearingLabel && point.targetBearing) {
			str.push(renderBearing(point.targetBearing));
		}


		if (app.subscribed && settings.showThermalClimbLabel && point.thermal_climb_rate) {
			str.push(renderSpeed(point.thermal_climb_rate, settings.verticalUnits, 1));
		}

		if (str.length==0) {
			return '';
		}
		return str.join(", ");
	}


	const labelPoints = function() {
		let pnts = points.filter(point => {
			if (!settings.showUnidentifiedLabels && !settings.simpleMode) {
				return !isPointUnidentifiedOrNot(point);
			}
			return true;
		});

		return pnts;
	}


	const labelGeoJSON = function() {

		var features = [];

		withLabelsCount.value = 0; // reset the labels count
		for (var i=0; i<points.length; i++) {

			let showLabel = true;

			if (points[i].object_type==15) continue; // skip if static object

			// skip un-identified labels but only if simple Dave mode isn't on...
			if (!settings.showUnidentifiedLabels && !settings.simpleMode) {
				if (isPointUnidentifiedOrNot(points[i])) showLabel = false;
			}
			withLabelsCount.value++;

			var point = points[i];

			let sizeOfLabel = 16;
			if (settings.simpleMode) sizeOfLabel=3;

			let time_ago_sec = Math.floor(Date.now() / 1000) - point.stamp;
			if (point.no_time_ago) time_ago_sec = Math.floor(app.hoverPoint.stamp / 1000) - point.stamp;

			//console.log(point);

			var feature = {
				"type": "Feature",
				 "properties": {
					"object_type": point.object_type,
					"course": point.course,
					"colour": point.colour,
					"name": point.name,
					"class": point.class,
					"source": point.source,
					// "targetDistance": renderDistance(point.targetDistance, settings.distanceUnits),
					// "targetBearing": renderBearing(point.targetBearing),
					"showLabel": showLabel,
					//"tz": point.tz,
					"key": point.key,
					"alt": renderHeight(point.alt, settings.altitudeUnits),
					"rego": point.rego,
					//"label": point.label,
					"shortLabel": pointShortName(point, sizeOfLabel, settings.shortenAircraftRegistrations),
					//"stamp": point.stamp,
					"time_ago_sec": time_ago_sec,
					"smallLabel": getSmallLabel(point)
				},
				"geometry": {
					"type": "Point",
					"coordinates": [point.long,point.lat]
				}
			};

			features.push(feature);
		}

		return {
				"type": "FeatureCollection",
				"features": features
			};
	};




	const geoJSON = function() {

		var features = [];

		for (var i=0; i<points.length; i++) {
			var point = points[i];

			let time_ago_sec = Math.floor(Date.now() / 1000) - point.stamp;
			if (point.no_time_ago) time_ago_sec = Math.floor(app.hoverPoint.stamp / 1000) - point.stamp;

			var feature = {
				"type": "Feature",
				 "properties": {
					"object_type": point.object_type,
					"course": point.course,
					"colour": point.colour,
					//"tz": point.tz,
					"key": point.key,
					"alt": renderHeight(point.alt, settings.altitudeUnits),
					"rego": point.rego,
					//"label": point.label,
					"shortLabel": pointShortName(point, 16, settings.shortenAircraftRegistrations),
					//"stamp": point.stamp,
					"time_ago_sec": time_ago_sec,
					"smallLabel": getSmallLabel(point)
				},
				"geometry": {
					"type": "Point",
					"coordinates": [point.long,point.lat]
				}
			};

			features.push(feature);
		}

		return {
				"type": "FeatureCollection",
				"features": features
			};
	};


	const selectedGeoJSON = function() {

		if (!selected) return null;
		if (!selected.value) return null;

		var selectedGeoJsonFeatures = {
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [selected.value.long, selected.value.lat]
			},
			 "properties": {
				"object_type": selected.value.object_type,
				"course": selected.value.course,
				"colour": selected.value.colour,
				//"tz": selected.value.tz,
				"key": selected.value.key,
				"alt": renderHeight(selected.value.alt, settings.altitudeUnits),
				"rego": selected.value.rego,
				"label": selected.value.label,
				"shortLabel": pointShortName(selected.value, 16, settings.shortenAircraftRegistrations),
				"time_ago_sec": Math.floor(Date.now() / 1000) - selected.value.stamp,
				"smallLabel": getSmallLabel(selected.value)
			}
		};
		return selectedGeoJsonFeatures;
	};


	function triggerPointsChanged() {
		//console.log('triggerPointsChanged called')
		pointsChanged.value++;

		addTaskStats();
		setSelected();
	}

	function empty() {
		//console.log('Emptying points');
		points.splice(0);
		legendPoints.value.splice(0);
		pointsCount.value=0;
	}

	function addPoint(point, totalPointsLength) {
		points.push(point);
		if (pointsCount.value==0) pointsCount.value=totalPointsLength;

		//if (pointsCount.value<=app.maxLegendPoints) { // should really be a variable

		if (isPointUnidentifiedOrNot(point)) point.unidentified=true;

		legendPoints.value.push(point);
	}

	function calculateTargetDistanceAndBearing() {
		if (points==null) return;
		if (points.length==0) return;

		var point1 = null;
		var point2 = null;
		var target_coords = measureToolData.targetCoordinates();

		if (target_coords==null) return;

		point1 = turfPoint(target_coords);
		for (var i=0; i<points.length; i++)
		{
			point2 = turfPoint([points[i].long, points[i].lat]);
			points[i].targetBearing = bearing(point1, point2);
			points[i].targetDistance = distance(point1, point2) * 1000; // km to meters
		}
	}


	return {
		points,
		labelPoints,
		legendPoints,
		addPoint,
		empty,
		geoJSON,
		labelGeoJSON,
		selectedGeoJSON,
		pointsCount,
		pointsChanged,
		triggerPointsChanged,
		getKey,
		selected,
		setSelected,
		clearSelected,
		getLegendByKey,
		withLabelsCount,
		checkForSelected,
		getSmallLabel,
		calculateTargetDistanceAndBearing,
		getAllKeys,
		addTaskStats,
		getTaskStats,
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(pointsStore, import.meta.hot))
}