import { watch } from 'vue';

import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { pointsStore } from "@/stores/PointsStore.js";

import { useGroups } from "@/Composables/UseGroups.js";

import { maxBy, minBy } from 'lodash';


export function useMapTools() {

	const app = appStateStore();
	const points = pointsStore();
	const settings = settingsStore();
	const groups = useGroups();

	function followingLength() {
		let count = settings.following.length;
		if (groups.group?.value?.members && settings.followGroup) {
			count = count + groups.group.value.members.length;
		}
		if (app.selected && !settings.isFollowed(app.selected)) count++;
		return count;
	}


	function clickPoint(point) {

		// console.log('Click point clicked');
		// console.log("point");
		// console.log(point);

		// console.log("app.selected");
		// console.log(app.selected);

		//app.selected=null;

		// only select if it's not already selected
		if (app.selected == point) {
			
			app.selected=null;

		} else {

			//console.log('setting show to selected');
			// ensure we don't have anything else on the map selected.

			app.deselectEverything(); 

			
			app.selected = point;


			// if we are paused and looking at other traffic
			if (app.hoveringOnChart) {
				app.hoverPoint = points.getKey(point);
			} 
			

		} 

		//settings.pauseFollowing = false;
	}


	function hasFollows() {
		let following = false;
		// if (settings.followSingle) following=true;
		//if (settings.followSelected && app.selected) following=true;
		if (groups.groupMembers.value?.length>0 && settings.followGroup) following=true;
		if (settings.following.length>0) following=true;
		return following;
	}


	function goToSelected() {

		var selectedList = [];

		// check if we are just getting a single, or the multi-selected list

		// check for a list of group regos
		if (groups.groupMembers.value && settings.followGroup) {

			for (var i=0; i<groups.groupMembers.value.length; i++) {

				// only add if not the currently selected point
				//if (!points.selected || groups.groupMembers.value[i].key!=points.selected.key) {
					var point = points.getKey(groups.groupMembers.value[i].key);
					if (point) {
						selectedList.push([point.long, point.lat]);
					}
				//}
			}

		}

		//console.log(settings.following);

		// get all the selected items
		if (settings.following.length>0) {
			for (var i=0; i<settings.following.length; i++) {

				// only add if not the currently selected point
				//if (!points.selected || settings.following[i]!=points.selected.key) {
					var point = points.getKey(settings.following[i]);
					if (point) {
						selectedList.push([point.long, point.lat]);
					}
					
				//}
			}
		}

		// if we don't have a selected list, then we can select just one
		if (app.selected) {
			var point = points.getKey(app.selected);
			if (point) {
				selectedList.push([point.long, point.lat]);
			}
		}


		// only continue if we have some items to zoom into
		if (selectedList.length==0) return;

		app.skipNextLoad = true; // make sure we don't reload after moving slightlys

		// check if we have 1 item, or multiple to zoom to bounds
		if (selectedList.length==1) {
			goTo(selectedList[0][1], selectedList[0][0]);
		} else {
			// go to the bounds of the multiple points
			workOutMapVisibleSpace();
			var bounds = getBounds(selectedList);
			app.map.fitBounds(bounds, {
				padding: 50
			});
		}
	}


	function goToPoint(point) {
		workOutMapVisibleSpace();
		goTo(point.lat, point.long);
	}

	function goTo(lat, long) {
		workOutMapVisibleSpace();
		app.map.flyTo({
			center: [long, lat]
		});
	}

	function goToZoom(lat, long, zoom) {
		workOutMapVisibleSpace();
		app.map.flyTo({
			center: [long, lat],
			zoom: zoom
		});
	}


	function workOutMapVisibleSpace() {

		let top=10 + app.headerHeight;
		let left=10;
		let bottom=10 + app.footerHeight;

		if (app.version==3) {
			// console.log('settings.legendPanelHeight');
			// console.log(settings.legendPanelHeight);

			let panelHeight = settings.legendPanelHeight;

			// only add the left panel if big
			if (app.windowWidth>640 && panelHeight>250) {
				left = 10 + app.leftWidth;
			}
		} else {
			left = 10 + app.leftWidth;
		}

		// check the display size and set the padding
		app.map.setPadding({left: left, top: top, bottom: bottom});
	}



	function getMinOrMax(markersObj, minOrMax, latOrLng) {

		if(minOrMax == "max"){
			return maxBy(markersObj, function (value) {
				return value[latOrLng]
			})[latOrLng];
		}else{
			return minBy(markersObj, function (value) {
				return value[latOrLng]
			})[latOrLng];
		}
	}

	// given a list of markers in the format [[long, lat],[long, lat]]
	// e.g. [[16.03182, 47.8076], [16.05395, 47.82793], [16.3367, 47.76315], [19.35723, 47.56087]]
	// returns the bounds [[16.03182, 47.56087], [19.35723, 47.82793]]
	function getBounds(markersObj) {
		var maxLat = getMinOrMax(markersObj, "max", 1);
		var minLat = getMinOrMax(markersObj, "min", 1);
		var maxLng = getMinOrMax(markersObj, "max", 0);
		var minLng = getMinOrMax(markersObj, "min", 0);

		var southWest = [minLng, minLat];
		var northEast = [maxLng, maxLat];
		return [southWest, northEast];
	}

	return { 
		goTo,
		goToSelected,
		goToPoint,
		hasFollows,
		goToZoom,
		workOutMapVisibleSpace,
		clickPoint,
		getBounds,
		followingLength
	}

}
